<template>
  <b-form @submit.prevent="onTeamSubmit">
    <b-row>
      <b-col class="col-12 mb-4 text-center">
        <b-avatar
          button
          class="mb-2 avatar-edit"
          badge-variant="transparent"
          variant="light"
          size="5em"
          :src="avatarUrl"
          @click="onAvatarClick"
        >
          <template #badge v-if="avatarUrl">
            <i class="simple-icon-camera"></i>
          </template>
        </b-avatar>
        <h5 v-if="teamData.name">{{ teamData.name }}</h5>
        <h5 v-else>[Name]</h5>
        <input
          id="fileUpload"
          ref="avatarFile"
          type="file"
          accept="image/svg"
          @change="onAvatarFileChange"
          hidden
        />
      </b-col>
      <b-col class="col-md-12 mb-3">
        <base-input
          id="name-input"
          key="name-input"
          label="Name:"
          placeholder="Name"
          v-model="teamData.name"
          validate
          required
          :v="$v.teamData.name"
          :error="setError($v.teamData.name, 'Name')"
          :loading="loading"
        ></base-input>
      </b-col>
    </b-row>
    <div class="d-flex">
      <div class="align-self-center ml-auto">
        <b-button variant="light" size="sm" @click="onCancelClick">
          <span class="label">Cancel</span>
        </b-button>
        <processing-button
          variant="primary"
          size="sm"
          :label="editMode ? 'Update' : 'Save'"
          class="ml-2"
          :processing="processingSimulationTeam"
        ></processing-button>
      </div>
    </div>
  </b-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { initializeTeam } from '@/utils/initialization'
import { managementMixin } from '@/mixins/managementMixin'
import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
export default {
  props: {
    editMode: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: () => {}
    }
  },
  mixins: [validationMixin, managementMixin],
  data() {
    return {
      teamData: initializeTeam(),
      newTeamForm: new FormData(),
      avatarUrl: ''
    }
  },
  validations: {
    teamData: {
      name: { required }
    }
  },
  methods: {
    ...mapActions(['addSimulationTeam', 'addTeam', 'fetchTeam', 'updateSimulationTeam']),
    onAvatarClick() {
      this.$refs['avatarFile'].click()
    },
    onAvatarFileChange(event) {
      let files = event.target.files
      const file = files[0]
      this.teamData.avatar = file
      this.avatarUrl = URL.createObjectURL(file)
    },
    async onTeamSubmit() {
      this.$v.teamData.$touch()

      // return if form error is captured
      if (this.$v.teamData.$pending || this.$v.teamData.$error) return

      // submit form
      const team = this.teamData
      const teamForm = this.newTeamForm

      teamForm.append('name', team.name)

      if (this.editMode) {
        teamForm.append('team_id', team.teamId)
      }

      if (this.avatarUrl && this.isAvatarUrlLocal) {
        teamForm.append('avatar', team.avatar)
      }

      this.processing = true
      if (this.editMode) {
        await this.updateSimulationTeam({ id: this.currentSimulation.id, payload: teamForm })
      } else {
        await this.addSimulationTeam({ id: this.currentSimulation.id, payload: teamForm })
      }

      this.processing = false
      this.formChanged = false
      this.$emit('itemSaved', true)
    },
    async resetTeamData() {
      this.teamData = initializeTeam()
      this.$v.$reset()
    }
  },
  computed: {
    ...mapGetters(['currentSimulation']),
    ...mapGetters('loading', ['processingSimulationTeam']),
    isAvatarUrlLocal() {
      let url = this.avatarUrl
      let regex = /^blob:/
      return regex.test(url)
    },
    team: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    selectedTeamType(newVal) {
      this.teamData.teamType = newVal.id
    },
    team: {
      handler(value) {
        if (value) {
          this.teamData = value
          this.avatarUrl = value.avatar
        }
      },
      immediate: true
    }
  }
}
</script>
