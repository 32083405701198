<template>
  <div class="teams pr-4">
    <div class="d-flex">
      <div class="align-self-center">
        <base-input
          placeholder="Search team..."
          v-model="filter"
          type="search"
          class="search-user-input"
        ></base-input>
      </div>
      <div class="align-self-center ml-auto">
        <b-button
          size="sm"
          variant="outline-primary"
          class="text-uppercase mr-2"
          @click="onNewTeamClick"
        >
          <span>Create Team</span>
        </b-button>
      </div>
    </div>
    <b-card body-class="management-card-body p-0">
      <h4 class="p-4 mb-0">Teams</h4>
      <action-table
        v-if="sortedTeams"
        actionTooltip="name"
        class="team-table"
        hasActions
        showAvatar
        tableClass="px-4"
        :key="reloadActionTable"
        :items="sortedTeams"
        :fields="fields"
        :perPage="5"
        :filter="filter"
        @itemClicked="onTeamClick"
        @itemDeleted="onTeamDelete"
      ></action-table>
    </b-card>

    <b-modal
      content-class="team-edit-modal"
      hide-header
      hide-footer
      size="md"
      v-model="showTeamModal"
    >
      <team-form
        :editMode="!isNewTeam"
        key="edit-team-form"
        v-model="teamData"
        @itemSaved="onTeamSave"
        @itemCancelled="onTeamCancel"
      ></team-form>
    </b-modal>

    <!-- Confirm Action -->
    <action-confirm-modal
      actionButton="Delete"
      actionButtonVariant="danger"
      v-model="showConfirmModal"
      :processing="processingSimulationTeam"
      @actionConfirmed="onActionConfirm"
      @actionCanceled="onActionCancel"
    >
      <template v-if="deletedTeam" v-slot:body>
        <h6>
          Are you sure you want to delete <b>{{ deletedTeam.name }}</b
          >?
        </h6>
        <p>This action cannot be undone once confirmed.</p>
      </template>
    </action-confirm-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { initializeTeam } from '@/utils/initialization'
import { managementMixin } from '@/mixins/managementMixin'
import { teamFields } from '@/data/admin'
import ActionConfirmModal from '@/components/Modals/ActionConfirmModal.vue'
import ActionTable from '@/components/Tables/ActionTable.vue'
import BaseInput from '@/components/Common/BaseInput.vue'
import PulseLoader from 'vue-spinner/src/PulseLoader'
import TeamForm from '@/components/Form/TeamForm.vue'
import variables from '@/assets/css/sass/themes/_perform.sim.scss'
export default {
  components: {
    ActionConfirmModal,
    ActionTable,
    BaseInput,
    PulseLoader,
    TeamForm
  },
  data() {
    return {
      deletedTeam: null,
      fields: teamFields,
      fill: variables.primaryColor,
      filter: null,
      processing: false,
      reloadActionTable: 0,
      showConfirmModal: false,
      showTeamModal: false,
      teamData: initializeTeam()
    }
  },
  mixins: [managementMixin],
  methods: {
    ...mapActions(['deleteSimulationTeam']),
    async getTeams() {
      await this.getSimulationTeams(this.currentSimulation.id)
    },
    onActionCancel() {
      this.triggerModal(false)
    },
    async onActionConfirm() {
      const item = this.deletedTeam
      const deletedTeam = { id: this.currentSimulation.id, teamId: item.teamId }
      await this.deleteSimulationTeam(deletedTeam)
      this.triggerModal(false)
      this.reloadTeam()
    },
    onNewTeamClick() {
      this.teamData = initializeTeam()
      this.triggerTeamModal(true)
    },
    onTeamClick(team) {
      this.teamData = _.cloneDeep(team)
      this.triggerTeamModal(true)
    },
    async onTeamDelete(item) {
      this.deletedTeam = item
      this.triggerModal(true)
    },
    async onTeamSave(value) {
      this.triggerTeamModal(false)
      await this.getTeams()
      this.reloadActionTable += 1
    },
    onTeamCancel() {
      this.triggerTeamModal(false)
    },
    async triggerModal(value) {
      this.showConfirmModal = value
    },
    async triggerTeamModal(value) {
      this.showTeamModal = value
    },
    reloadTeam() {
      this.getTeams()
      this.reloadActionTable += 1
    }
  },
  computed: {
    ...mapGetters(['currentSimulation', 'simulationTeams']),
    ...mapGetters('loading', ['processingSimulationTeam']),
    hasTeams() {
      return this.simulationTeams.length > 0
    },
    isNewTeam() {
      return !this.teamData.teamId
    },
    sortedTeams() {
      return this.simulationTeams.sort((a, b) => a.name.localeCompare(b.name))
    }
  },
  async created() {
    await this.getTeams()
  }
}
</script>
